var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-comission",
        title: _vm.$t("Aplicar gorjeta para o garçom"),
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formComission",
          attrs: { name: "formComission" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "modal_comissionFee",
                          name: "comissionFee",
                          label: _vm.$t("Comissões disponíveis"),
                          type: "radio",
                          options: _vm.getComissionRadioOptions,
                        },
                        model: {
                          value: _vm.form.comissionFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comissionFee", _vm._n($$v))
                          },
                          expression: "form.comissionFee",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_discount-btn_cancel",
                          text: _vm.$t("Cancelar"),
                          variant: "danger",
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_discount-btn_confirm",
                          text: _vm.$t("Confirmar"),
                          variant: "primary",
                          type: "submit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }