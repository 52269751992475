<template>
  <b-modal
    id="modal-comission"
    :title="$t('Aplicar gorjeta para o garçom')"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    centered
    @hidden="resetModal"
  >
    <FormulateForm
      ref="formComission"
      name="formComission"
      @submit="onConfirm"
    >
      <b-container>
        <b-row>
          <b-col>
            <FormulateInput
              id="modal_comissionFee"
              v-model.number="form.comissionFee"
              name="comissionFee"
              :label="$t('Comissões disponíveis')"
              type="radio"
              :options="getComissionRadioOptions"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <e-button
              id="modal_discount-btn_cancel"
              :text="$t('Cancelar')"
              class="mr-1"
              variant="danger"
              :text-shortcuts="['ESC']"
              @click="hideModal"
            />

            <e-button
              id="modal_discount-btn_confirm"
              :text="$t('Confirmar')"
              variant="primary"
              type="submit"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'
import { formulateHelper } from '@/mixins'
import delegablePermissions from '@/utils/delegable-permissions'
import EButton from '@/views/components/EButton.vue'

const getInitialForm = () => ({
  comissionFee: 0,
  delegateUserData: null,
})

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [formulateHelper],

  props: {
    comissionFee: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      form: getInitialForm(),
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),

    ...mapGetters('pages/pdv/payBoxConfiguration', ['barConfig']),

    getComissionRadioOptions() {
      const defaultResult = [{ value: 0, label: this.$t('Nenhum') }]
      const result =
        this.barConfig?.storeComissions?.map(c => ({
          value: c.comissionFee,
          label: this.$options.filters.percentage(c.comissionFee),
        })) || []

      return [...result, ...defaultResult]
    },
  },

  methods: {
    onConfirm() {
      this.$emit('click-confirm', this.form)
      this.hideModal()
    },

    resetModal() {
      this.form = getInitialForm()
      this.delegateUserData = null
    },

    hideModal() {
      this.$bvModal.hide('modal-comission')
    },
    async showModal(initialData) {
      try {
        if (initialData?.comissionFee > 0) {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_UPDATE_WAITER_COMISSION
          )
        }

        this.$bvModal.show('modal-comission')
        this.form.comissionFee = initialData?.comissionFee || 0
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}

.input-size {
  font-size: 1.4rem;
}
</style>
